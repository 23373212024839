<template>
  <SkeletonAuthor v-if="!author || (['pending', 'idle'].includes(status) && !author)" />
  <div v-else-if="author" class="flex items-center space-x-3">
    <UAvatar v-if="author.avatar_urls" :src="useFeaturedImageUrl(author.avatar_urls[96],60,60)" size="md" :alt="author.name" loading="lazy" />
    <div class="flex flex-col justify-start group/author" :class="{ 'text-sm': small }">
      <ULink :to="`/@${author.slug}`" class="overflow-hidden line-clamp-1 font-medium leading-tight">{{ author.name }}</ULink>
      <ULink class="text-gray-400 overflow-hidden line-clamp-1 leading-tight group-hover/author:underline" :to="`/@${author.slug}`">@{{ author.slug }}</ULink>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { WP_REST_API_User } from 'wp-types';

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  small: {
    type: Boolean,
    default: false
  }
});

const { public: { baseURL } } = useRuntimeConfig()

const { data: author } = useNuxtData<WP_REST_API_User>(`author-voice-widget-${props.id}`)

const { status } = await useLazyFetch(`/wp/v2/users/${props.id}`, {
  baseURL,
  key: `author-voice-widget-${props.id}`,
  query: {
    _fields: 'id,name,slug,avatar_urls',
  },
  default() {
    return author.value
  }
});
</script>
